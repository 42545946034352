@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-BlackItalic.e1e001ae.woff2) format('woff2'),
        url(/static/media/Gotham-BlackItalic.d7ae07ee.woff) format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url(/static/media/Gotham-Ultra.0818cfd1.woff2) format('woff2'),
        url(/static/media/Gotham-Ultra.4d0c3ec7.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-Thin.be20ad61.woff2) format('woff2'),
        url(/static/media/Gotham-Thin.f0c99e2d.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-MediumItalic.b2ed0eb9.woff2) format('woff2'),
        url(/static/media/Gotham-MediumItalic.1b1c30bb.woff) format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-Bold.e420119f.woff2) format('woff2'),
        url(/static/media/Gotham-Bold.f95835df.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-Light.2bd49396.woff2) format('woff2'),
        url(/static/media/Gotham-Light.1fab6e01.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url(/static/media/Gotham-UltraItalic.2f3ab628.woff2) format('woff2'),
        url(/static/media/Gotham-UltraItalic.a8756104.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Book';
    src: url(/static/media/Gotham-BookItalic.fcdbea5a.woff2) format('woff2'),
        url(/static/media/Gotham-BookItalic.97d3c2ab.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-LightItalic.99c0ab15.woff2) format('woff2'),
        url(/static/media/Gotham-LightItalic.e9960150.woff) format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-ThinItalic.608c742b.woff2) format('woff2'),
        url(/static/media/Gotham-ThinItalic.8ec2ae31.woff) format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Extra';
    src: url(/static/media/Gotham-ExtraLightItalic.5cdf4b84.woff2) format('woff2'),
        url(/static/media/Gotham-ExtraLightItalic.e6d4316b.woff) format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Extra';
    src: url(/static/media/Gotham-ExtraLight.087346ee.woff2) format('woff2'),
        url(/static/media/Gotham-ExtraLight.66e5fe87.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-Black.a661b391.woff2) format('woff2'),
        url(/static/media/Gotham-Black.f7ac6a67.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Book';
    src: url(/static/media/Gotham-Book.3a8663e5.woff2) format('woff2'),
        url(/static/media/Gotham-Book.5acb7203.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-Medium.e159b394.woff2) format('woff2'),
        url(/static/media/Gotham-Medium.fbfe0ab5.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-BoldItalic.504e9fea.woff2) format('woff2'),
        url(/static/media/Gotham-BoldItalic.7ea008ab.woff) format('woff');
    font-weight: bold;
    font-style: italic;
}

body {
    font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
}
