@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('./assets/fonts/Gotham-Ultra.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Ultra.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Thin.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Bold.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Light.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Ultra';
    src: url('./assets/fonts/Gotham-UltraItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-UltraItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('./assets/fonts/Gotham-BookItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Extra';
    src: url('./assets/fonts/Gotham-ExtraLightItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Extra';
    src: url('./assets/fonts/Gotham-ExtraLight.woff2') format('woff2'),
        url('./assets/fonts/Gotham-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Black.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Book';
    src: url('./assets/fonts/Gotham-Book.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-Medium.woff2') format('woff2'),
        url('./assets/fonts/Gotham-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('./assets/fonts/Gotham-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/Gotham-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

body {
    font-family: 'Gotham', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
}